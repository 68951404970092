import { ErrorResponse, Notification, NotificationList } from '@citruscamps/citrus-client'
import { faSpinner, faCircle as farCircle } from '@fortawesome/pro-regular-svg-icons'
import {
  faArrowLeft,
  faBell,
  faEllipsisV,
  faRedo,
  faTrashAlt,
  faTrashUndo,
  faCircle as fasCircle,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import Link from 'next/link'
import { Fragment, useState } from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { ROUTES } from '../../constants/routes'
import { usePopper } from '../../hooks/usePopper'
import { ErrorContent } from '../ErrorContent/ErrorContent'
import { LoadingButton } from '../LoadingButton/LoadingButton'
import { LoadingRows } from '../LoadingRows/LoadingRows'
import { Popover } from '../Popover/Popover'
import { Tooltip } from '../Tooltip/Tooltip'
import { TruncatedText } from '../TruncatedText/TruncatedText'
import { NotificationFetchProps, useFetchNotifications } from './hooks/useFetchNotifications'

interface IProps {
  programId: string
  className?: string
}

export const NotificationsButton = ({ programId, className }: IProps) => {
  const [isShowingActions, setIsShowingActions] = useState(false)
  const {
    data: notifications,
    error,
    hasNextPage,
    isError,
    isLoading,
    isFetching,
    hasUnread,
    fetchNextPage,
    handleMarkAllAsRead,
    handleArchiveAll,
    handleMarkAsRead,
    handleMarkAsUnread,
    handleArchive,
    handleUnarchive,
    setFetchProps,
  } = useFetchNotifications({
    programId,
    fetchProps: {
      sort: 'created',
      order: 'DESC',
      filter: { is_archived: false, topic_group: ['customer'] },
    },
  })

  const { target, toggle, ...tooltipProps } = usePopper<HTMLButtonElement>({
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -1],
        },
      },
    ],
    handleToggle: () => {
      setIsShowingActions(false)
    },
  })
  const targetProps = {
    ref: target,
  }
  const isInitialLoading = isLoading && notifications?.length === 0
  return (
    <>
      <button
        {...targetProps}
        aria-label="Notifications"
        className={['btn btn-link', tooltipProps.isShown ? 'active' : '', className || ''].join(
          ' ',
        )}
        onClick={(): void => {
          if (!isInitialLoading) toggle()
        }}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        disabled={!notifications || undefined}
      >
        {isInitialLoading && <FontAwesomeIcon icon={faSpinner} spin />}
        {!isInitialLoading && (
          <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon={faBell} flip="horizontal" transform="up-2" />
            {hasUnread && (
              <FontAwesomeIcon
                icon={fasCircle}
                className="text-danger"
                transform="shrink-8 right-8 down-8"
              />
            )}
          </span>
        )}
      </button>
      <Popover
        {...tooltipProps}
        toggle={toggle}
        bodyProps={{ className: 'popover-body scrollable-container pt-0 px-1' }}
        style={{ minWidth: 'min(100vw, 320px)' }}
      >
        {!isShowingActions && (
          <>
            <div className="dropdown-header d-flex justify-content-between pb-3">
              <h6 className="m-0 pt-2">Notifications</h6>
              <button
                aria-label="Notifications actions"
                className="btn btn-link text-dark p-0"
                onClick={() => setIsShowingActions(true)}
              >
                <FontAwesomeIcon icon={faEllipsisV} />
              </button>
            </div>
            {tooltipProps.isShown && (
              <div style={{ maxHeight: '65vh', overflowY: 'scroll' }}>
                <NotificationListView
                  notifications={notifications}
                  error={error || undefined}
                  isError={isError}
                  isLoading={isLoading}
                  isFetching={isFetching}
                  hasNextPage={hasNextPage}
                  fetchNextPage={fetchNextPage}
                  setFetchProps={setFetchProps}
                  onMarkAsRead={handleMarkAsRead}
                  onMarkAsUnread={handleMarkAsUnread}
                  onArchive={handleArchive}
                  onUnarchive={handleUnarchive}
                />
              </div>
            )}
          </>
        )}
        {isShowingActions && (
          <>
            <div className="dropdown-header d-flex justify-content-between pb-3">
              <h6 className="m-0 pt-2">Notifications</h6>
              <button
                className="btn btn-link text-dark p-0"
                onClick={() => setIsShowingActions(false)}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
            </div>
            <NotificationActions
              programId={programId}
              toggle={() => toggle()}
              onMarkAllAsRead={handleMarkAllAsRead}
              onArchiveAll={handleArchiveAll}
            />
          </>
        )}
      </Popover>
    </>
  )
}

interface INotificationListViewProps {
  notifications: Notification[]
  error?: ErrorResponse
  isError: boolean
  isLoading: boolean
  isFetching: boolean
  hasNextPage: boolean
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<NotificationList, ErrorResponse>>
  setFetchProps: (value?: NotificationFetchProps) => void
  onMarkAsRead: (id: string) => Promise<void>
  onMarkAsUnread: (id: string) => Promise<void>
  onArchive: (id: string) => Promise<void>
  onUnarchive: (id: string) => Promise<void>
}

export const NotificationListView = (props: INotificationListViewProps) => {
  const {
    notifications,
    error,
    isError,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    setFetchProps,
    onMarkAsRead: handleMarkAsRead,
    onMarkAsUnread: handleMarkAsUnread,
    onArchive: handleArchive,
    onUnarchive: handleUnarchive,
  } = props

  const [infiniteRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore: () => fetchNextPage(),
    disabled: !!error,
  })

  if (isLoading) {
    return <LoadingRows />
  }

  if (isError) {
    return (
      <>
        <hr className="my-3" />
        <ErrorContent size="xs" className="text-muted" error={error}>
          <button
            type="button"
            className="btn btn-sm btn-outline-primary"
            onClick={() => {
              setFetchProps()
            }}
          >
            <FontAwesomeIcon className="mr-2" icon={faRedo} />
            Refresh list
          </button>
        </ErrorContent>
      </>
    )
  }
  if (notifications.length === 0) {
    return (
      <div className="text-center text-muted px-3">
        You have no notifications at this time.
        <br /> Check back later.
      </div>
    )
  }
  return (
    <>
      {notifications.map((notification: Notification, i: number) => (
        <Fragment key={notification.id}>
          <hr className="my-0" />
          <NotificationDropdownItem
            className="dropdown-item px-3"
            notification={notification}
            onMarkAsRead={handleMarkAsRead}
            onMarkAsUnread={handleMarkAsUnread}
            onArchive={handleArchive}
            onUnarchive={handleUnarchive}
          />
        </Fragment>
      ))}
      {isFetching && <LoadingRows />}
      <div ref={infiniteRef}></div>
    </>
  )
}

interface INotificationActionProps {
  programId: string
  toggle: () => void
  onMarkAllAsRead: () => Promise<void>
  onArchiveAll: () => Promise<void>
}

export const NotificationActions = ({
  programId,
  toggle,
  onMarkAllAsRead: handleMarkAllAsRead,
  onArchiveAll: handleArchiveAll,
}: INotificationActionProps) => {
  return (
    <>
      <hr className="my-0" />
      <LoadingButton
        type="button"
        className="dropdown-item"
        onClick={async () => {
          await handleMarkAllAsRead()
          toggle()
        }}
      >
        Mark all as read
      </LoadingButton>
      <hr className="my-0" />
      <LoadingButton
        type="button"
        className="dropdown-item"
        onClick={async () => {
          await handleArchiveAll()
          toggle()
        }}
      >
        Archive all
      </LoadingButton>
      <hr className="my-0" />
      <Link
        href={{
          pathname: ROUTES.PREFERENCES,
          query: { program_id: programId },
        }}
        className="dropdown-item"
      >
        Preferences
      </Link>
    </>
  )
}

interface INotificationDropdownItemProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  notification: Notification
  onMarkAsRead: (id: string) => Promise<void>
  onMarkAsUnread: (id: string) => Promise<void>
  onArchive: (id: string) => Promise<void>
  onUnarchive: (id: string) => Promise<void>
}

const NotificationDropdownItem = ({
  notification,
  onMarkAsRead: handleMarkAsRead,
  onMarkAsUnread: handleMarkAsUnread,
  onArchive: handleArchive,
  onUnarchive: handleUnarchive,
  ...props
}: INotificationDropdownItemProps) => {
  return (
    <div {...props}>
      <div className="row">
        <div className="col-10">
          <button
            type="button"
            className="btn btn-link text-dark text-decoration-none p-0 text-left text-wrap"
            style={{ boxShadow: 'none' }}
            onClick={async () => {
              await handleMarkAsRead(notification.id)
              window.location.href = notification.url
            }}
          >
            <TruncatedText lines={2} className="h6 m-0 p-0">
              {notification.subject}
            </TruncatedText>
            <div className="pb-2">
              <span className="text-muted small">
                {DateTime.fromJSDate(new Date(notification.created)).toLocaleString(
                  DateTime.DATE_FULL,
                )}
              </span>
            </div>
          </button>
        </div>
        <div className="col-2">
          <div>
            {notification.is_read && (
              <Tooltip
                title="Mark as unread"
                placement="left"
                popupProps={{ style: { zIndex: 1090 } }}
              >
                <LoadingButton
                  aria-label="Mark as unread"
                  type="button"
                  className="btn btn-link btn-sm text-muted"
                  onClick={async () => await handleMarkAsUnread(notification.id)}
                >
                  <FontAwesomeIcon icon={farCircle} fixedWidth={true} />
                </LoadingButton>
              </Tooltip>
            )}
            {!notification.is_read && (
              <Tooltip
                title="Mark as read"
                placement="left"
                popupProps={{ style: { zIndex: 1090 } }}
              >
                <LoadingButton
                  aria-label="Mark as read"
                  type="button"
                  className="btn btn-link btn-sm text-danger"
                  onClick={async () => await handleMarkAsRead(notification.id)}
                >
                  <FontAwesomeIcon icon={fasCircle} transform="shrink-6" fixedWidth={true} />
                </LoadingButton>
              </Tooltip>
            )}
          </div>
          <div>
            {!notification.is_archived ? (
              <Tooltip title="Archive" placement="left" popupProps={{ style: { zIndex: 1090 } }}>
                <LoadingButton
                  aria-label="Archive"
                  type="button"
                  className="btn btn-link btn-sm"
                  onClick={async () => await handleArchive(notification.id)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} fixedWidth={true} />
                </LoadingButton>
              </Tooltip>
            ) : (
              <Tooltip title="Unarchive" placement="left" popupProps={{ style: { zIndex: 1090 } }}>
                <LoadingButton
                  aria-label="Unarchive"
                  type="button"
                  className="btn btn-link btn-sm"
                  onClick={async () => await handleUnarchive(notification.id)}
                >
                  <FontAwesomeIcon icon={faTrashUndo} fixedWidth={true} />
                </LoadingButton>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
