import { CustomerProfile } from '@citruscamps/citrus-client'
import { faArrowLeft, faBars, faSpinner, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { UrlObject } from 'url'
import { ROUTES } from '../../constants/routes'
import { useProgram } from '../../hooks/useFetchProgram'
import { useFetchTrafficSource } from '../../hooks/useFetchTrafficSource'
import { useSupport } from '../../hooks/useSupport'
import { lightOrDarkHeader } from '../../utils/branding-utils'
import { formatEventType, formatFullName } from '../../utils/formatters'
import { FeatureFlag } from '../FeatureFlag/FeatureFlag'
import { Icon } from '../Icon/Icon'
import { NotificationsButton } from '../NotificationsButton/NotificationsButton'
import { TruncatedText } from '../TruncatedText/TruncatedText'
import { UserDropdown } from '../UserDropdown/UserDropdown'
import { CheckoutDropdown } from './components/CheckoutDropdown'
import { CheckoutDropdownV2 } from './components/CheckoutDropdownV2'

interface IProps extends React.HTMLAttributes<HTMLElement> {
  returnTo?: string | UrlObject
  hideNavigation?: boolean
  hideSignIn?: boolean
  customerProfile?: CustomerProfile
  isLoading?: boolean
  theme?: 'light' | 'dark'
}

export const HeaderNavigation = ({
  hideNavigation,
  hideSignIn,
  returnTo,
  className,
  style,
  customerProfile,
  isLoading = false,
  theme,
  ...props
}: IProps) => {
  const { query, route } = useRouter()
  const { setIsShown: setIsShownSupport } = useSupport()
  const { program, isLoading: isLoadingProgram } = useProgram()
  const { data: trafficSource } = useFetchTrafficSource()
  const [showCollapsed, setShowCollasped] = useState<boolean>(false)
  theme = theme || lightOrDarkHeader(program?.preferences)
  if (isLoading && isLoadingProgram) {
    return null
  }
  return (
    <nav
      {...props}
      className={`navbar-header navbar navbar-expand-md ${
        theme === 'dark' ? 'navbar-dark' : 'navbar-light'
      } position-absolute w-100 ${
        showCollapsed ? 'h-100  bg-primary position-fixed' : 'bg-transparent'
      } ${className || ''}`.trim()}
      style={{ zIndex: 1040, ...style }}
    >
      <div className="container-fluid d-flex h-100">
        {hideNavigation ? null : returnTo ? (
          <Link
            href={returnTo}
            className="btn btn-light btn-back border shadow-sm py-md-2 px-md-3 my-2 mr-auto"
            style={{
              textTransform: 'unset',
              fontWeight: 400,
            }}
            passHref
          >
            <span className="m-auto">
              <FontAwesomeIcon icon={faArrowLeft} />
              <span className="d-none d-md-inline ml-2">Back</span>
            </span>
          </Link>
        ) : (
          <>
            {program && (
              <button
                type="button"
                className="navbar-toggler mb-auto p-3 border-0"
                onClick={(): void => {
                  setShowCollasped(!showCollapsed)
                }}
                data-toggle="collapse"
                data-target="#sidebarNavigation"
                aria-controls="sidebarNavigation"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <FontAwesomeIcon icon={showCollapsed ? faTimes : faBars} />
              </button>
            )}
            <div
              className={['collapse navbar-collapse mb-auto ', showCollapsed ? 'show' : ''].join(
                ' ',
              )}
              id="sidebarNavigation"
            >
              {program && (
                <ul className="navbar-nav">
                  {program.website && (
                    <li className="nav-item px-3 px-md-1">
                      <a
                        className="nav-link"
                        href={
                          program?.website.includes('//')
                            ? program?.website
                            : `//${program?.website}`
                        }
                      >
                        Home
                      </a>
                    </li>
                  )}
                  <li
                    className={`nav-item px-3 px-md-1${
                      route === ROUTES.EXPLORE ? ' font-weight-bold active' : ''
                    }`}
                  >
                    <Link
                      href={{
                        pathname: ROUTES.EXPLORE,
                        query: { program_id: program.id },
                      }}
                      className="nav-link"
                      passHref
                    >
                      Upcoming Events
                    </Link>
                  </li>
                  <FeatureFlag flagKey="memberships_enabled">
                    <li
                      className={`nav-item px-3 px-md-1${
                        route === ROUTES.MEMBERSHIP_PACKAGES ? ' font-weight-bold active' : ''
                      }`}
                    >
                      <Link
                        href={{
                          pathname: ROUTES.MEMBERSHIP_PACKAGES,
                          query: {
                            program_id: program.id,
                            ...(!trafficSource
                              ? {
                                  utm_source: 'citrus',
                                  utm_medium: 'internal',
                                  utm_content: 'header',
                                  utm_term: 'memberships',
                                }
                              : {}),
                          },
                        }}
                        className="nav-link"
                        onClick={() => setShowCollasped(false)}
                        passHref
                      >
                        Memberships
                      </Link>
                    </li>
                  </FeatureFlag>
                  <FeatureFlag flagKey="sessions_enabled">
                    <li
                      className={`nav-item px-3 px-md-1${
                        route === ROUTES.SESSION_PACKAGES ? ' font-weight-bold active' : ''
                      }`}
                    >
                      <Link
                        href={{
                          pathname: ROUTES.SESSION_PACKAGES,
                          query: {
                            program_id: program.id,
                            ...(!trafficSource
                              ? {
                                  utm_source: 'citrus',
                                  utm_medium: 'internal',
                                  utm_content: 'header',
                                  utm_term: 'buy+session',
                                }
                              : {}),
                          },
                        }}
                        className="nav-link"
                        onClick={() => setShowCollasped(false)}
                        passHref
                      >
                        Buy Sessions
                      </Link>
                    </li>
                  </FeatureFlag>
                  <FeatureFlag flagKey="appointments_enabled">
                    <li
                      className={`nav-item px-3 px-md-1${
                        route === ROUTES.EXPLORE_APPOINTMENTS ? ' font-weight-bold active' : ''
                      }`}
                    >
                      <Link
                        href={{
                          pathname: ROUTES.EXPLORE_APPOINTMENTS,
                          query: { program_id: program.id },
                        }}
                        className="nav-link"
                        onClick={() => setShowCollasped(false)}
                        passHref
                      >
                        Individual {formatEventType(program)}
                      </Link>
                    </li>
                  </FeatureFlag>
                  <li className="nav-item px-3 px-md-1 d-inline-block d-md-none">
                    {isLoading ? (
                      <div className="nav-link" onClick={() => setShowCollasped(false)}>
                        <FontAwesomeIcon
                          icon={faSpinner}
                          className="fa-spin input-icon position-relative d-inline-block"
                        />
                      </div>
                    ) : !customerProfile ? (
                      <Link
                        href={{ pathname: ROUTES.LOGIN, query }}
                        className="nav-link"
                        onClick={() => setShowCollasped(false)}
                        passHref
                      >
                        Sign in / Sign up
                      </Link>
                    ) : (
                      <Link
                        href={{ pathname: ROUTES.PROFILE, query }}
                        className="nav-link"
                        onClick={() => setShowCollasped(false)}
                        passHref
                      >
                        <div className="d-flex w-100">
                          <Icon
                            src={customerProfile?.picture_url}
                            className="avatar-img avatar-sm bg-light border rounded-circle shadow-sm my-auto"
                            alt={customerProfile?.first_name}
                            placeholder="/imgs/placeholder-user.jpg"
                          />
                          <div className="float-left ml-2 my-auto">
                            <TruncatedText className="font-weight-bold text-decoration-none h6 mt-2 mb-0">
                              {formatFullName(customerProfile)}
                            </TruncatedText>
                            <div className="small">View Account</div>
                          </div>
                        </div>
                      </Link>
                    )}
                  </li>
                  <hr className="my-3 d-block d-md-none" />
                  {/* TODO: add Citrus Support */}
                  {/* <li className="nav-item px-3 px-md-1 d-block d-md-none">
                      <button
                        type="button"
                        className="btn nav-link text-capitalize font-weight-normal"
                        onClick={() => {
                          setShowCollasped(false)
                          setIsShownSupport(true)
                        }}
                      >
                        Technical Support
                      </button>
                    </li>
                    <li className="nav-item px-3 px-md-1 d-block d-md-none">
                      <button
                        type="button"
                        className="btn nav-link text-capitalize font-weight-normal"
                        onClick={() => {
                          setShowCollasped(false)
                          setIsShownSupport(true)
                        }}
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                        Send feedback to Citrus
                      </button>
                    </li> */}
                  <FeatureFlag flagKey="communications_enabled">
                    <li className="nav-item px-3 px-md-1 d-block d-md-none">
                      <button
                        type="button"
                        className="btn nav-link text-capitalize font-weight-normal"
                        onClick={() => {
                          setShowCollasped(false)
                          setIsShownSupport(true)
                        }}
                      >
                        Contact {program.name || 'program'}
                      </button>
                    </li>
                  </FeatureFlag>
                </ul>
              )}
            </div>
          </>
        )}

        <div className={`navbar-nav ml-auto d-md-flex${showCollapsed ? ' d-none' : ''}`}>
          <FeatureFlag flagKey="communications_enabled">
            <div className="nav-item px-3 px-md-1">
              <button
                className="btn btn-link nav-link d-none d-md-inline-block"
                onClick={() => setIsShownSupport(true)}
              >
                Help
              </button>
            </div>
          </FeatureFlag>
          <FeatureFlag
            flagKey="products_enabled"
            fallback={
              <>{!!customerProfile && <CheckoutDropdown program={program} theme={theme} />}</>
            }
          >
            {!!customerProfile && <CheckoutDropdownV2 program={program} theme={theme} />}
          </FeatureFlag>
          {!hideSignIn && !!customerProfile && !!program?.id && (
            <div className="nav-item px-3 px-md-1">
              <NotificationsButton
                programId={program?.id}
                className="nav-link d-none d-md-inline-block"
              />
            </div>
          )}
          {!hideSignIn && program && (
            <UserDropdown
              customer={customerProfile}
              program={program}
              theme={theme}
              loading={isLoading}
            />
          )}
        </div>
      </div>
    </nav>
  )
}
